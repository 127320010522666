<template lang="pug">
    v-container
        loader(v-if="$root.loader")
        div.terms-page.mx-auto(v-else style="max-width:1024px;text-align:justify;")
            img.mt-2(height="70" src="/public/img/logo.png" alt="Wappid" @click="$router.push({path:'/login'})") 
            <p><strong>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</strong></p>
            <p>&nbsp;</p>
            <p>&Uacute;ltima actualizaci&oacute;n: 08 de Junio de 2021</p>
            <p>&nbsp;</p>
            <p>Est&aacute; usted ingresando a <strong><em>WAPPID</em></strong>&reg; <em>de ahora adelante la denominaremos <strong>WAPPID</strong></em>. Los siguientes T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad de uso rigen todos los aspectos relacionados con su cuenta en <strong><em>WAPPID, </em></strong>le recomendamos leerlos atentamente.</p>
            <p>&nbsp;</p>
            <ol>
            <li>SOBRE <em>WAPPID</em>&reg;</li>
            </ol>
            <p>&nbsp;</p>
            <p><em>Wappid</em>&reg; es una plataforma tecnol&oacute;gica que brinda una soluci&oacute;n de comunicaci&oacute;n efectiva y afectiva entre personas, mediante la creaci&oacute;n y utilidad de una <em>red</em> de referidos o invitados, orientada a incrementar la cantidad de personas que se adhieren a una causa en com&uacute;n. La causa puede ser una causa econ&oacute;mica, pol&iacute;tica, social o de cualquier categor&iacute;a de inter&eacute;s humano.&nbsp;</p>
            <p>&nbsp;</p>
            <p><em>Wappid</em>&reg; basa su operaci&oacute;n en el uso de WAPPID social <em>WhatsApp</em> en conjunto con innovadoras t&eacute;cnicas y herramientas de neurociencias aplicadas que permiten anticipar la tendencia de comportamiento social respecto de una causa o tema de inter&eacute;s com&uacute;n. De tal forma que es posible definir la reacci&oacute;n del ser humano ante las situaciones que se le presenten en la vida, por ejemplo en mercadotecnia pol&iacute;tica, es posible hacer proyecciones para calcular el comportamiento (reacci&oacute;n, preferencia) de los votantes hacia los candidatos de los diversos partidos pol&iacute;ticos en una contienda electoral.</p>
            <p>&nbsp;</p>
            <ol start="2">
            <li>APROBACI&Oacute;N</li>
            </ol>
            <p>&nbsp;</p>
            <p>Los productos y servicios que nuestra plataforma <em>Wappid</em>&reg; le proporciona a partir de la creaci&oacute;n de su cuenta, se ofrecen sujetos a la aceptaci&oacute;n de estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, nuestra Pol&iacute;tica de Privacidad, y todos los t&eacute;rminos y pol&iacute;ticas adicionales.&nbsp;</p>
            <p>&nbsp;</p>
            <p>Al utilizar los productos o servicios de <strong><em>WAPPID</em></strong>, al marcar la casilla de selecci&oacute;n o al hacer clic en un cuadro de aceptaci&oacute;n de estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, usted acepta cumplir estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, nuestra Pol&iacute;tica de privacidad y todos los dem&aacute;s t&eacute;rminos y pol&iacute;ticas aplicables. Si no est&aacute; de acuerdo con estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, no use ni acceda a nuestros productos o servicios, no marque la casilla de selecci&oacute;n, ni haga clic en el cuadro de aceptaci&oacute;n.</p>
            <p>&nbsp;</p>
            <p>Los productos y servicios de <strong><em>WAPPID </em></strong>est&aacute;n disponibles &uacute;nica y exclusivamente para personas que tienen al menos 18 a&ntilde;os de edad, por lo tanto, con la aceptaci&oacute;n de estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em> usted declara y garantiza que tiene al menos 18 a&ntilde;os de edad y asume toda la responsabilidad de la selecci&oacute;n y el uso de los servicios. Estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em> son nulos donde lo proh&iacute;ba la ley y el derecho de acceso a los productos y servicios se revoca en dichas jurisdicciones.</p>
            <p>&nbsp;</p>
            <ol start="3">
            <li>REGISTRO</li>
            </ol>
            <p>&nbsp;</p>
            <p>Para utilizar los productos y servicios de <strong><em>WAPPID</em></strong>, usted deber&aacute; registrarse para obtener una cuenta de servicios. Para crear una cuenta <em>en <strong>WAPPID</strong></em> le solicitar&aacute; proporcionar algunos de los siguientes datos (mismos que ser&aacute;n tratados conforme a lo estipulado en nuestra Pol&iacute;tica de Privacidad):</p>
            <ul>
            <li>Correo electr&oacute;nico (en duplicado para efectos de confirmaci&oacute;n)</li>
            <li>Contrase&ntilde;a</li>
            <li>Nombre(s) y apellidos</li>
            <li>Pa&iacute;s donde habita actualmente</li>
            <li>Estado/Depertamento/Provincia donde habita actualmente</li>
            <li>N&uacute;mero celular de WhatsApp</li>
            <li>N&uacute;mero de documento de identificaci&oacute;n</li>
            </ul>
            <p>&nbsp;</p>
            <p>Adicionalmente, se le podr&aacute; solicitar: responder una o m&aacute;s preguntas de selecci&oacute;n m&uacute;ltiple que reflejan su grado de inter&eacute;s a participar de forma activa con nosotros; el nombre de la persona que lo invit&oacute; (refiri&oacute;) o por qui&eacute;n o c&oacute;mo se enter&oacute; de <strong><em>WAPPID</em></strong> a la que se est&aacute; incorporando al momento de crear su cuenta; indicar el n&uacute;mero de invitados (referidos) que podr&iacute;a ayudar a conseguir para adherirse a <strong><em>WAPPID</em></strong> a la que usted se est&aacute; uniendo; as&iacute; como otras preguntas que nos permitan conocerle mejor.</p>
            <p>&nbsp;</p>
            <p>Durante el proceso de registro, adicionalmente se le pedir&aacute; confirmar el WhatsApp para seguir recibiendo informaci&oacute;n acerca de <strong><em>WAPPID</em></strong>.</p>
            <p>&nbsp;</p>
            <p>Al momento de responder los campos solicitados al crear su cuenta, usted garantiza que cualquier informaci&oacute;n de registro (como nombre, informaci&oacute;n de contacto y cualquier otra informaci&oacute;n) que env&iacute;e a <strong><em>WAPPID</em></strong> es precisa, completa y no enga&ntilde;osa, y acepta mantener dicha informaci&oacute;n actualizada. De lo contrario, se constituye en un incumplimiento de estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em> y puede derivar en la cancelaci&oacute;n inmediata de su cuenta. Nos reservamos el derecho, a nuestro exclusivo criterio, de cancelar o rechazar el registro de cualquier persona que consideremos que viole estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, nuestras pol&iacute;ticas, la ley o, en general, creamos que pueda ser perjudicial para <strong><em>WAPPID</em></strong>. Usted ser&aacute; responsable de mantener la confidencialidad de su contrase&ntilde;a y as&iacute; como el resto de la informaci&oacute;n de cuenta. Su correo electr&oacute;nico debe ser utilizado por una sola persona, de forma que no podr&aacute; usar un correo electr&oacute;nico de otra persona con la intenci&oacute;n de suplantarle, asimismo no se permite el inicio de sesi&oacute;n &uacute;nico compartido por varias personas. <strong><em>WAPPID</em></strong> no es responsable por ninguna p&eacute;rdida o da&ntilde;o por no cumplir con estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>. Podemos usar su informaci&oacute;n de registro y cualquier informaci&oacute;n t&eacute;cnica sobre su uso de nuestros productos y servicios para realizar mejoras a <strong><em>WAPPID</em></strong>, adaptar nuestras presentaciones a usted, facilitar su usabilidad, comunicarnos por separado con usted o publicar el hecho de que usted es un usuario de nuestros servicios.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="4">
            <li>CONTENIDO Y USO DE UNA RED</li>
            </ol>
            <p>&nbsp;</p>
            <p>Usted conserva la propiedad de los derechos de propiedad intelectual del contenido que nos env&iacute;a, excepto por los derechos limitados que nos permiten realizar nuestros productos y servicios. En resumen, lo que es suyo es suyo, pero necesitamos ciertos permisos suyos para que nuestro procesamiento, mantenimiento, almacenamiento, reproducci&oacute;n t&eacute;cnica, copia de seguridad y manejo relacionado de su contenido no infrinja los derechos de autor aplicables y otras leyes. Por lo tanto, para realizar los productos y servicios de<em> <strong>WAPPID</strong></em>, usted nos otorga una licencia no exclusiva, mundial, sin regal&iacute;as, transferible e irrevocable (mientras su contenido est&eacute; almacenado) para usar, reproducir y mostrar su contenido como es razonablemente necesario para proporcionarle los productos y/o servicios de <strong><em>WAPPID</em></strong>. En la medida m&aacute;xima permitida por la ley aplicable, no nos haremos responsables por la p&eacute;rdida o modificaci&oacute;n de su contenido.</p>
            <p>&nbsp;</p>
            <p>Como parte de la administraci&oacute;n de su cuenta en <strong><em>WAPPID</em></strong>, usted tiene derecho a modificar en cualquier momento los datos proporcionados inicialmente (datos de registro y fotograf&iacute;a de perfil), inclusive puede optar en cualquier momento, si as&iacute; lo considera conveniente, eliminar su cuenta de <strong><em>WAPPID</em></strong>.</p>
            <p>&nbsp;</p>
            <p>Al utilizar su cuenta en <strong><em>WAPPID</em></strong>, usted podr&aacute; tener acceso a los diversos servicios integrados como parte del producto (red a la que usted se uni&oacute; al momento de crear su cuenta), teniendo completo derecho a navegar y utilizar la herramienta tecnol&oacute;gica a su conveniencia. Como parte de la interfaz de usuario usted podr&aacute; visualizar y tener completo acceso a las siguientes secciones:</p>
            <ul>
            <li>Mis invitados
            <ul>
            <li>Las personas que aceptaron su invitaci&oacute;n</li>
            <li>Las personas que se han registrado por medio de su red</li>
            <li>Mi distribuci&oacute;n de referidos&nbsp;</li>
            <li>Total de usuarios invitados</li>
            <li>Conversaciones</li>
            </ul>
            </li>
            <li>Mis Rankings&nbsp;
            <ul>
            <li>Teniendo acceso a las primeras 50 posiciones dentro de <strong><em>WAPPID</em></strong>, en forma geogr&aacute;fica y por unidad de tiempo&nbsp;</li>
            <li>Ranking nacional, regional y local</li>
            </ul>
            </li>
            <li>Compartir
            <ul>
            <li>La interfaz de <strong><em>WAPPID</em></strong> le proporcionar&aacute; su propio link que podr&aacute; compartir en sus redes sociales, por:
            <ul>
            <li>WhatsApp</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>Correo electr&oacute;nico</li>
            </ul>
            </li>
            <li>Galer&iacute;a
            <ul>
            <li>Espacio donde podr&aacute; subir fotograf&iacute;as con pie de foto para que los dem&aacute;s miembros de <strong><em>WAPPID</em></strong> puedan visualizarlas. (Nota: Para modificar o eliminar dichas fotograf&iacute;as es necesario solicitarlo v&iacute;a correo electr&oacute;nico al Administrador de <strong><em>WAPPID</em></strong> al correo electr&oacute;nico: jcorredor@algorithmous.com)</li>
            </ul>
            </li>
            <li>Mis usuarios&nbsp;
            <ul>
            <li>En esta secci&oacute;n podr&aacute; tener acceso a los datos de otras personas que no est&aacute;n precisamente en su red de los cuales el Administrador de<strong><em> WAPPID</em></strong> le encargar&aacute; su administraci&oacute;n si usted lo acepta.&nbsp;</li>
            <li>El administrador de <strong><em>WAPPID</em></strong> te dar&aacute; las instrucciones para la administraci&oacute;n de dichos usuarios.</li>
            </ul>
            </li>
            <li>Mis redes
            <ul>
            <li>Se trata de las diferentes redes <em>Wappid</em>&reg; bajo la cuenta del mismo administrador en las que usted ya se encuentra registrado.</li>
            </ul>
            </li>
            <li>Redes Disponibles
            <ul>
            <li>Se trata de las diferentes redes <em>Wappid</em>&reg; bajo la cuenta del mismo administrador a las cuales usted puede unirse con un s&oacute;lo clic.</li>
            </ul>
            </li>
            </ul>
            </li>
            </ul>
            <p>&nbsp;</p>
            <ol start="5">
            <li>NUESTRO CONTENIDO</li>
            </ol>
            <p>&nbsp;</p>
            <p>El uso de los productos y servicios de <strong><em>WAPPID</em></strong>, no le otorga la propiedad de ning&uacute;n derecho de propiedad intelectual en los productos y servicios. Usted acepta que todo el contenido y materiales de <strong><em>WAPPID</em></strong>, entregados a trav&eacute;s de los Servicios o puestos a disposici&oacute;n por <strong><em>WAPPID</em></strong> (colectivamente, "Nuestro contenido") est&aacute;n protegidos por derechos de autor, marcas comerciales, marcas de servicio, patentes, secretos comerciales u otras leyes y derechos de propiedad. Excepto por lo expresamente autorizado por <strong><em>WAPPID</em></strong> por escrito, usted acepta no vender, licenciar, alquilar, modificar, distribuir, copiar, reproducir, transmitir, mostrar p&uacute;blicamente, publicar, adaptar, editar o crear trabajos derivados de Nuestro contenido. Sin embargo, puede imprimir o descargar una cantidad razonable de copias de Nuestro contenido para sus propios fines informativos; siempre que conserve todos los avisos de derechos de autor y otros avisos de propiedad dentro de las copias. Queda estrictamente prohibida la reproducci&oacute;n, copia o distribuci&oacute;n de cualquiera de nuestros elementos de dise&ntilde;o de Contenido de <strong><em>WAPPID</em></strong> para cualquier otro prop&oacute;sito sin nuestro permiso previo por escrito. El uso de nuestro contenido para cualquier prop&oacute;sito no expresamente permitido en estos t&eacute;rminos est&aacute; prohibido. <strong><em>WAPPID</em></strong> se reserva todos los derechos no expresamente otorgados en estos T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad.</p>
            <p>&nbsp;</p>
            <ol start="6">
            <li>USO DE LOS SERVICIOS.</li>
            </ol>
            <p>&nbsp;</p>
            <p>Usted declara y garantiza que el uso que usted realice de los productos y servicios de <strong><em>WAPPID</em></strong>, cumplir&aacute; con todas las leyes y normativas en su pa&iacute;s y en el pa&iacute;s de origen de <strong><em>WAPPID</em></strong>; uso que no infringir&aacute; ni violar&aacute; los derechos de propiedad intelectual de terceros ni ninguna ley o regulaci&oacute;n (incluidas, entre otras, las leyes sobre obscenidad, difamaci&oacute;n y privacidad); si usa los productos y servicios de <strong><em>WAPPID</em></strong> en nombre de un tercero, deber&aacute; contar con todas las autorizaciones necesarias; y su uso no entrar&aacute; en conflicto con ninguna obligaci&oacute;n que tenga con ning&uacute;n tercero. Nos reservamos el derecho, a nuestro exclusivo criterio, de eliminar, modificar, evitar el acceso o negarnos a mostrar su contenido que consideremos que viola estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>, nuestras pol&iacute;ticas o la ley.</p>
            <p>&nbsp;</p>
            <ol start="7">
            <li>CAMBIO DE LOS SERVICIOS</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong><em>WAPPID</em></strong> est&aacute; constantemente innovando y evolucionando sus productos y servicios para proporcionar la mejor experiencia posible para nuestros usuarios. Usted reconoce y acepta que la forma y la naturaleza de los productos y servicios pueden cambiar ocasionalmente sin previo aviso. Los cambios en la forma y naturaleza de los productos y servicios pueden incluir, entre otros, la alteraci&oacute;n o eliminaci&oacute;n de una funcionalidad o aspecto de los mismos. Usted acepta que no seremos responsables ante usted ni ante ning&uacute;n tercero por ninguna modificaci&oacute;n, suspensi&oacute;n o interrupci&oacute;n de ninguna parte de los productos y servicios. Tambi&eacute;n acepta que podemos crear l&iacute;mites en ciertas funciones y servicios o restringir su acceso a partes de los productos y servicios sin previo aviso ni responsabilidad.</p>
            <p>&nbsp;</p>
            <ol start="8">
            <li>POL&Iacute;TICA ANTI-SPAM PARA EMAILS Y WHATSAPP.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong><em>WAPPID</em></strong> proh&iacute;be expresamente todo tipo de SPAM (el t&eacute;rmino "SPAM" significa el env&iacute;o de correos electr&oacute;nicos o WhatsApp no solicitados por desconocidos por el remitente). Si se determina que est&aacute; utilizando los productos y servicios para SPAM, su cuenta estar&aacute; sujeta a rescisi&oacute;n inmediata. Si recibimos reclamos sobre sus actividades bajo esta Secci&oacute;n, su cuenta puede estar sujeta a rescisi&oacute;n inmediata. No tendremos ninguna responsabilidad u obligaci&oacute;n por los mensajes u otro contenido creado por usted. No debe enviar, publicar, distribuir o difundir ning&uacute;n mensaje, material o informaci&oacute;n difamatoria, obscena o ilegal, incluida la informaci&oacute;n de propiedad de otra persona (incluidas marcas comerciales, secretos comerciales o informaci&oacute;n protegida por derechos de autor) sin la autorizaci&oacute;n expresa del titular de los derechos. <strong><em>WAPPID</em></strong> cooperar&aacute; con las autoridades legales en la divulgaci&oacute;n de nombres y direcciones IP de usuarios que est&aacute;n involucrados en SPAM o actividades ilegales.</p>
            <p>&nbsp;</p>
            <ol start="9">
                <li>POL&Iacute;TICA DE USO DE SERVICIOS DE GOOGLE.</li>
            </ol>
            <p>&nbsp;</p>
            <p>Los usuarios con los perfiles "Administradores" en <strong><em>WAPPID</em></strong> tienen la posibilidad de conectar su cuenta de Google para sincronizar el contacto (número de teléfono, nombres, ciudad) de sus usuarios en la red, mediante el uso de los API's de Contactos de Google.</p>
            <p>
                <strong><em>WAPPID</em></strong> requiere de algunos permisos para poder acceder a las API's de Contactos que Google provee, los cuales son:
                <ul>
                    <li>Ver la dirección de correo electrónico principal de tu Cuenta de Google</li>
                    <li>Permite ver su información personal, incluidos los datos personales que haya hecho públicos</li>
                    <li>Ver, editar, descargar y borrar permanentemente tus contactos</li>
                </ul>
            </p>
            <p>
               <strong><em>WAPPID</em></strong> solamente sincroniza el número de teléfono, nombres y ciudad de los usuarios del sistema, con la cuenta de Google conectada al usuario del perfil "Administradores".
            </p>
            <p>
                <strong><em>WAPPID</em></strong> en ningún caso elimina, modifica o lee los contactos de la cuenta de Google conectada, solamente crea los contactos, para que el usuario guarde los contactos de <strong><em>WAPPID</em></strong> en su cuenta de Google y pueda sincronizarlos en su teléfono posteriormente.
            </p>
            <p>
                | El proceso de conexión y uso de los servicios de Google consta de los siguientes pasos:
                <ul>
                    <li>El usuario de los perfiles "Administradores" ingresa al sistema de Wappid y conecta su cuenta de Google aceptando los permisos requeridos para el uso de los servicios de Google</li>
                    <li>El sistema programa la sincronización de los contactos asociados a el usuario</li>
                    <li>El sistema crea el contacto en Google con el número de teléfono, nombres y ciudad</li>
                    <li>El proceso se repite cada vez que un usuario se registra en la red y queda asociado a el usuario que tiene la cuenta de Google conectada</li>
                    <li>Solamente se sincronizan 25.000 contactos en la cuenta de Google conectada debido a los limites de servicio de Google</li>
                </ul>
            </p>
            <p>
                <strong><em>WAPPID</em></strong> solamente sincroniza los contactos del sistema a una cuenta de Google conectada para que el usuario se pueda comunicar con sus contactos desde su teléfono celular mediante WhatsApp, en ningún caso recopila o transforma los datos mas alla de lo descrito en este numeral.
            </p>
            <p>
                | Las llaves o tokens de autenticación se guardan de manera segura y siguiendo las instrucciones de conexión a los servicios de Google, el usuario tiene la posibilidad de desvincular su cuenta de Google en cualquier momento, al desvincular la cuenta se borran los tokens, llaves que se generaron de todo el sistema.
            </p>
            <p>&nbsp;</p>
            <ol start="10">
            <li>OTRAS REESTRICCIONES</li>
            </ol>
            <p>&nbsp;</p>
            <p>No deber&aacute; utilizar ning&uacute;n "deep-link", "page-rascado", "robot", "spider" u otro dispositivo autom&aacute;tico, programa, algoritmo o metodolog&iacute;a, ni ning&uacute;n proceso manual similar o equivalente para acceder, adquirir, copiar o controlar cualquier parte de los productos y/o servicios de nuestro contenido; no deber&aacute; reproducir de cualquier manera&nbsp; la estructura de navegaci&oacute;n o la presentaci&oacute;n de los productos y servicios para obtener o intentar obtener cualquier material, documento o informaci&oacute;n a trav&eacute;s de cualquier medio que no est&eacute; disponible a prop&oacute;sito a trav&eacute;s de los productos y servicios. Adem&aacute;s, no deber&aacute; usar ning&uacute;n dispositivo, software o rutina para interferir o intentar interferir con el correcto funcionamiento de los productos y servicios o cualquier transacci&oacute;n que se realice en los productos y servicios. Nos reservamos el derecho de prohibir cualquier actividad de este tipo.</p>
            <p>&nbsp;</p>
            <p>No deber&aacute; intentar obtener acceso no autorizado a ninguna parte o funci&oacute;n de los productos y servicios, a ning&uacute;n sistema o red conectado a los productos y servicios, a ning&uacute;n servidor de <strong><em>WAPPID</em></strong>, o a ninguno de los productos y servicios ofrecidos en o a trav&eacute;s de los productos y servicios, mediante pirater&iacute;a, "miner&iacute;a de contrase&ntilde;as" o cualquier otro medio ileg&iacute;timo.</p>
            <p>&nbsp;</p>
            <p>No deber&aacute; probar, escanear o probar la vulnerabilidad de los productos y servicios o cualquier red conectada a los productos y servicios, ni violar las medidas de seguridad o autenticaci&oacute;n en los Servicios ni en ninguna red conectada a los productos y servicios. No deber&aacute; revertir la b&uacute;squeda, rastrear o intentar rastrear la informaci&oacute;n de ning&uacute;n otro usuario o visitante de los Servicios o cualquier otro usuario de <em>WAPPID</em>, ni explotar los productos y servicios o cualquier servicio o informaci&oacute;n disponible u ofrecida por o a trav&eacute;s de nuestros productos y servicios.</p>
            <p>No deber&aacute; realizar ninguna acci&oacute;n que imponga una carga irracional o desproporcionadamente grande en la infraestructura de los productos y servicios o en los sistemas o redes de WAPPID, o en cualquier sistema o red conectados a los productos y servicios. En el caso de tales acciones, nos reservamos el derecho de implementar medidas para garantizar la calidad y disponibilidad de los productos y servicios para todos los dem&aacute;s usuarios de <strong><em>WAPPID</em></strong>.</p>
            <p>&nbsp;</p>
            <p>No deber&aacute; falsificar encabezados ni manipular identificadores para ocultar el origen de cualquier mensaje o transmisi&oacute;n que env&iacute;e a <strong><em>WAPPID</em></strong> o que env&iacute;e mientras usa los productos y servicios. No deber&aacute;, en relaci&oacute;n con los productos y servicios, pretender (por ejemplo, a trav&eacute;s de suplantaci&oacute;n) que usted es cualquier otra persona o entidad. En el caso de las encuestas <em>Wappid</em>&reg; de <strong><em>WAPPID</em></strong> enviadas en relaci&oacute;n con los productos y servicios, no deber&aacute; intentar eliminar ning&uacute;n pie de p&aacute;gina de identificaci&oacute;n como "Desarrollado por <strong><em>WAPPID</em></strong>" u otro mensaje similar.</p>
            <p>No deber&aacute; abusar ni amenazar con abusar (verbalmente, f&iacute;sicamente o por escrito) a ning&uacute;n cliente, empleado o agente de <strong><em>WAPPID</em></strong>.</p>
            <p>&nbsp;</p>
            <ol start="11">
            <li>INDEMNIZACI&Oacute;N</li>
            </ol>
            <p>&nbsp;</p>
            <p>Usted es responsable de todas sus actividades en relaci&oacute;n con los productos y servicios y defender&aacute;, indemnizar&aacute; y eximir&aacute; de responsabilidad a <strong><em>WAPPID</em></strong> y a cada uno de sus empleados, contratistas, directores, proveedores y representantes de todas las responsabilidades, da&ntilde;os, p&eacute;rdidas, acuerdos, reclamaciones, acciones, demandas, costos y gastos, incluidos los honorarios de abogados, que surjan de o en relaci&oacute;n con uso o uso indebido de los productos y servicios, acceso a cualquier parte de <strong><em>WAPPID</em></strong> y los productos y servicios, su contenido, o violaci&oacute;n de estos <em>T&eacute;rminos y Condiciones y pol&iacute;ticas de privacidad</em>.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
</template>

<script>

    import auth from 'mixins/auth'
    export default {
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Wappid - Términos y condiciones y políticas de privacidad')
            }
        }
    }
</script>